import React, { useRef, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { decodeQueryString, canUseWindow } from '../../utils'
import Image from '../Image'

const INITIAL = 'INITIAL'
const SUBMITTING = 'SUBMITTING'
const SUCCESS = 'SUCCESS'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const Modal = ({ closeModal }) => {
  const queryString = (canUseWindow && window.location.search) || ''
  const {
    firstName: initFirstName,
    lastName: initLastName,
    email: initEmail,
    team: initTeam,
  } = decodeQueryString(queryString)

  const [firstName, setFirstName] = useState(initFirstName || '')
  const [lastName, setLastName] = useState(initLastName || '')
  const [email, setEmail] = useState(initEmail || '')
  const [team, setTeam] = useState(initTeam || '')
  const [state, setState] = useState(INITIAL)

  const ref = useRef()

  useOutsideClick(ref, () => {
    closeModal && closeModal()
  })

  useEffect(
    () => {
      if (state === SUBMITTING) {
        const timeout = setTimeout(() => {
          setState(SUCCESS)

          canUseWindow &&
            window.dataLayer &&
            window.dataLayer.push({ event: 'hackathonFormSubmitted' })
        }, 2800)

        return () => clearTimeout(timeout)
      }
    },
    [state]
  )

  const handleSubmit = e => {
    e.preventDefault()
    canUseWindow && window.Autopilot && window.Autopilot.run('associate', email)

    setState(SUBMITTING)
  }

  const renderContent = () => {
    switch (state) {
      case SUCCESS:
        return renderSuccess()
      case SUBMITTING:
      case INITIAL:
      default:
        return renderForm()
    }
  }

  const isSubmitting = state === SUBMITTING

  const renderSuccess = () => (
    <>
      <Image type={'checkInbox'} />
      <h3 className="u-text-p3 u-mt-md u-mb-md" style={{ lineHeight: 1.1 }}>
        Welcome to <span className="u-text-mark">.txtlss</span>!
      </h3>
      <p className="u-text-p6">
        It's great to have you on board, {firstName}! Please{' '}
        <strong>check your inbox</strong> for next steps.{' '}
      </p>
      <p className="u-text-p6">
        We sent you a link to the Slack community, helpful resources and all the
        information to get you started.
      </p>
      <button
        type="submit"
        className="c-btn v--dark u-basis-4 u-w-full u-mt-xl"
        onClick={closeModal}
      >
        OK, great!
      </button>
    </>
  )

  const renderForm = () => (
    <>
      <h3 className="u-text-p3 u-mb-md" style={{ lineHeight: 1.1 }}>
        Register for the <span className="u-text-mark">.txtlss</span> event
      </h3>
      <p className="u-text-p6 u-mb-md">
        Register to get access to the private community, where participants can
        meet, exchange ideas and make new friends.
      </p>
      <form
        method="post"
        onSubmit={handleSubmit}
        id="txtlss-registration"
        name="txtlss-registration"
      >
        <label
          htmlFor="txtlss-fist-name"
          className="u-text-p6-bold us-text-gray-100"
        >
          Your first name *
        </label>
        <input
          id="txtlss-first-name"
          name="txtlss-first-name"
          type="text"
          className="u-my-sm"
          placeholder="Jane"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required
        />
        <label
          htmlFor="txtlss-last-name"
          className="u-text-p6-bold us-text-gray-100"
        >
          Your last name *
        </label>
        <input
          id="txtlss-last-name"
          name="txltss-last-name"
          type="text"
          className="u-my-sm"
          placeholder="Doe"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          required
        />
        <label
          htmlFor="txtlss-email"
          className="u-text-p6-bold us-text-gray-100"
        >
          Your email address *
        </label>
        <input
          id="txtlss-email"
          name="txltss-email"
          type="email"
          placeholder="your@email.com"
          className="u-my-sm"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <label
          htmlFor="txtlss-team"
          className="u-text-p6-bold us-text-gray-100"
        >
          Name of your Team
        </label>
        <input
          id="txtlss-team"
          name="txltss-team"
          type="text"
          placeholder="Blue Team"
          className="u-my-sm"
          value={team}
          onChange={e => setTeam(e.target.value)}
        />
        <input
          id="txtlss-agreement"
          name="txltss-agreement"
          type="checkbox"
          placeholder="your@email.com"
          className="u-mr-sm"
          required
        />
        <label
          htmlFor="txtlss-agreement"
          className="u-text-p6-bold us-text-gray-100"
        >
          I accept <Link to="/txtlss/terms/">Terms &amp; Conditions</Link>
        </label>
        {isSubmitting && (
          <p className="u-text-p6 u-mt-xl u-md-sm">
            Hang on tight, we're using text to process your registration
            request...
          </p>
        )}
        <input
          type="submit"
          className="c-btn v--dark u-basis-4 u-w-full u-mt-xl"
          // disabled={isSubmitting}
          value={isSubmitting ? 'Registering...' : 'Register now'}
        />
      </form>
    </>
  )

  return (
    <motion.div
      ref={ref}
      animate={{
        y: 0,
        opacity: 1,
        rotateX: 0,
      }}
      initial={{
        y: 10,
        opacity: 0,
      }}
      exit={{
        y: -10,
        opacity: 0,
      }}
      transition={{ ease: 'easeOut', duration: 0.15 }}
      className="txtlss-modal u-p-xl u-rounded u-text-black u-bg-white"
    >
      {renderContent()}
    </motion.div>
  )
}

Modal.propTypes = {
  closeModal: PropTypes.func,
}

export default Modal
