import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { Main } from '../components'
import './styles/index.less'
import TxtlssHeader from '../components/Header/TxtlssHeader'
import Overlay from '../components/Txtlss/Overlay'
import Modal from '../components/Txtlss/Modal'

const Layout = ({
  children,
  overlay,
  modal,
  handleOpenModal,
  handleCloseModal,
}) => {
  return (
    <div>
      <AnimatePresence>
        {overlay && <Overlay key="overlay" />}
        {modal && <Modal closeModal={handleCloseModal} />}
      </AnimatePresence>
      <TxtlssHeader
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
      <Main>{children}</Main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  modal: PropTypes.bool,
  overlay: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleOpenModal: PropTypes.func,
}

export default Layout
