import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../layout/txlss-layout'
import { Rules, SEO } from '../components'

class Rule extends React.Component {
  render() {
    const rule = this.props.data.markdownRemark
    const { title, date } = rule.frontmatter

    return (
      <Layout location={this.props.location}>
        <SEO />
        <Rules title={title} subtitle={date}>
          <div dangerouslySetInnerHTML={{ __html: rule.html }} />
        </Rules>
      </Layout>
    )
  }
}

Rule.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Rule

export const query = graphql`
  query hackathonTermsQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        index
      }
      frontmatter {
        slug
        title
        author
        excerpt
        categories
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
