import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import TxtlssLogo from '../../assets/icons/txtlss-logo.svg'

const TxtlssHeader = ({ handleOpenModal }) => {
  return (
    <div className="c-navbar c-navbar-txtlss u-bg-black">
      <div className="c-navbar__content">
        <div className="u-flex u-items-center fixed-width">
          <Link to="/txtlss">
            <TxtlssLogo className="txtlss-logo" />
          </Link>
          <div className="u-text-p6-bold u-ml-md tagline">
            Virtual Hackathon <br />
            by <Link to="/">LiveChat Platform</Link>
          </div>
        </div>

        {/* <div className="u-text-p3 u-flex-2 u-text-center dates-column">
          open till 10/25
        </div>
        <div className="u-text-right fixed-width cta-column">
          {handleOpenModal ? (
            <a
              href={'#signup'}
              onClick={handleOpenModal}
              className="c-btn v--livechat u-text-white u-text-p6-bold"
            >
              Register now
            </a>
          ) : (
            <Link
              to={'/txtlss/'}
              className="c-btn v--livechat u-text-white u-text-p6-bold"
            >
              Back to event page
            </Link>
          )}
        </div> */}
      </div>
    </div>
  )
}

TxtlssHeader.propTypes = {
  handleOpenModal: PropTypes.func,
}

export default TxtlssHeader
